import React, { Suspense, useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "./component/Home/home.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "./component/Screens/admin-panel/AuthContext";
const Home = React.lazy(() => import("./component/Home/home"));
const Blog = React.lazy(() => import("./component/Screens/Blog/blog"));
const About = React.lazy(() => import("./component/Screens/About/about"));
const OfferPage = React.lazy(() =>
  import("./component/Screens/OfferPage/offer-page")
);
const OfferPageDrive = React.lazy(() =>
  import("./component/Screens/offer-page-drive/offer-page-drive")
);
const OfferPageRider = React.lazy(() =>
  import("./component/Screens/offer-page-rider/offer-page-rider")
);
const BikePooling = React.lazy(() =>
  import("./component/Screens/Bike-pooling/bike-pooling")
);
const RideShare = React.lazy(() =>
  import("./component/Screens/Ride-share/ride-share")
);
const CarpoolingPage = React.lazy(() =>
  import("./component/Screens/Carpooling/carpooling")
);
const Customer = React.lazy(() =>
  import("./component/Screens/CustomerStories/customer")
);

const NoVarification = React.lazy(() =>
  import("./component/Screens/FindPool/number-varification")
);
const PhoneNumber = React.lazy(() =>
  import("./component/Screens/FindPool/phone-number")
);
const RideGivers = React.lazy(() =>
  import("./component/Screens/FindPool/ride-givers")
);
const PickupLocation = React.lazy(() =>
  import("./component/Screens/FindPool/pickup-location")
);
const DropLocation = React.lazy(() =>
  import("./component/Screens/FindPool/drop-location")
);
const ConfirmRide = React.lazy(() =>
  import("./component/Screens/OfferPool/confirm-ride")
);
const ProgressRide = React.lazy(() =>
  import("./component/Screens/OfferPool/progress-ride")
);
const CarRide = React.lazy(() =>
  import("./component/Screens/OfferPool/car-ride")
);
const OfferPickupLocation = React.lazy(() =>
  import("./component/Screens/OfferPool/offer-pickup-location")
);
const OfferDropLocation = React.lazy(() =>
  import("./component/Screens/OfferPool/offer-drop-location")
);
const OfferPhoneNumber = React.lazy(() =>
  import("./component/Screens/OfferPool/offer-phone-number")
);
const OfferVarifyNumber = React.lazy(() =>
  import("./component/Screens/OfferPool/offer-varify-number")
);
const OfferPoolDetails = React.lazy(() =>
  import("./component/Screens/offer-pool-detail/offer-pool-details")
);
const FindPoolDetails = React.lazy(() =>
  import("./component/Screens/find-pool-detail/find-pool-details")
);
const ReferEarn = React.lazy(() =>
  import("./component/Screens/Refer-earn/refer-and-earn")
);
const MediaGallery = React.lazy(() =>
  import("./component/Screens/MediaGallery/media-gallery")
);
const UserData = React.lazy(() =>
  import("./component/Screens/FindPool/userData")
);
const MyRides = React.lazy(() =>
  import("./component/Screens/FindPool/myRides")
);
const EditPool = React.lazy(() =>
  import("./component/Screens/FindPool/editPool")
);
const PickupLocationUpdate = React.lazy(() =>
  import("./component/Screens/FindPool/pickupLocationUpdate")
);
const DropLocationUpdate = React.lazy(() =>
  import("./component/Screens/FindPool/dropLocationUpdate")
);
const PrivacyPolicy = React.lazy(() =>
  import("./component/Screens/privacy-policy/privacy-policy")
);
const TermCondition = React.lazy(() =>
  import("./component/Screens/term-condition/term-condition")
);
const AdminPanel = React.lazy(() =>
  import("./component/Screens/admin-panel/main-panel")
);
const CreateBlogBlog = React.lazy(() =>
  import("./component/Screens/admin-panel/createBlogMain")
);
const CreateBlogBlogSection = React.lazy(() =>
  import("./component/Screens/admin-panel/create-blog-section-main")
);
const BlogBlogEdit = React.lazy(() =>
  import("./component/Screens/admin-panel/editBlog")
);
const AllBlogsCard = React.lazy(() =>
  import("./component/Screens/Blog/AllBlogs")
);
const BlogSectionShow = React.lazy(() =>
  import("./component/Screens/admin-panel/blog-section")
);
const BlogSectionEdit = React.lazy(() =>
  import("./component/Screens/admin-panel/edit-section")
);
const BlogCardDetail = React.lazy(() =>
  import("./component/Screens/Blog/BlogSectionDetail")
);
const PurposeSeo = React.lazy(() =>
  import("./component/Screens/admin-panel/ManageSeo")
);
const SeoList = React.lazy(() =>
  import("./component/Screens/admin-panel/seo-list")
);
const EditSeo = React.lazy(() =>
  import("./component/Screens/admin-panel/edit-seo")
);
const AdminLogin = React.lazy(() =>
  import("./component/Screens/admin-panel/admin-login")
);
const Career = React.lazy(() => import("./component/Screens/Career/Career"));
const App = () => {
  const customToastStyle = {
    top: "50%",
    left: "19%",
    transform: "translate(-50%, -50%)",
  };
  const { isLoggedIn } = useContext(AuthContext);

  const [showGif, setShowGif] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setShowGif(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const closeGif = () => setShowGif(false);
  // const closeGif = useCallback(() => setShowGif(false), []);
  return (
    <>
      {/* <>
        <Modal
          show={showGif}
          onHide={closeGif}
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Body className="text-center position-relative">
            <AiOutlineClose className="close-icon" onClick={closeGif} />
            <img
              src="../assets/banner/popup.gif"
              alt="Loading GIF"
              className="img-fluid"
              loading="lazy"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </Modal.Body>
        </Modal>
      </> */}
      <Suspense fallback={<div></div>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/blogs" element={<Blog />} />
            <Route exact path="/blogs/:slug" element={<BlogCardDetail />} />

            <Route
              exact
              path="/number-varification"
              element={<NoVarification />}
            />
            <Route exact path="/user-data" element={<UserData />} />
            <Route exact path="/phone-number" element={<PhoneNumber />} />
            <Route exact path="/ride-givers" element={<RideGivers />} />
            <Route exact path="/pickup-location" element={<PickupLocation />} />
            <Route exact path="/drop-location" element={<DropLocation />} />
            <Route exact path="/confirm-ride" element={<ConfirmRide />} />
            <Route exact path="/progress-ride" element={<ProgressRide />} />
            <Route exact path="/car-ride" element={<CarRide />} />
            <Route exact path="/Career" element={<Career />} />

            <Route
              exact
              path="/offer-pickup-location"
              element={<OfferPickupLocation />}
            />
            <Route
              exact
              path="/offer-drop-location"
              element={<OfferDropLocation />}
            />
            <Route
              exact
              path="/offer-phone-number"
              element={<OfferPhoneNumber />}
            />
            <Route
              exact
              path="/offer-varify-number"
              element={<OfferVarifyNumber />}
            />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/customer" element={<Customer />} />
            <Route
              exact
              path="/offer-pool-rides"
              element={<OfferPoolDetails />}
            />
            <Route
              exact
              path="/find-pool-detail"
              element={<FindPoolDetails />}
            />
            <Route exact path="/refer-and-earn" element={<ReferEarn />} />
            <Route exact path="/media-gallery" element={<MediaGallery />} />
            <Route exact path="/my-rides" element={<MyRides />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/term-condition" element={<TermCondition />} />
            <Route exact path="/carpool-update-ride" element={<EditPool />} />
            <Route
              exact
              path="/pickup-update"
              element={<PickupLocationUpdate />}
            />
            <Route exact path="/drop-update" element={<DropLocationUpdate />} />

            <Route
              exact
              path="/best-bikepool-service-in-delhi-ncr"
              element={<BikePooling />}
            />
            <Route
              exact
              path="/best-ridesharing-service-in-delhi-ncr"
              element={<RideShare />}
            />

            <Route
              exact
              path="/best-carpooling-service-in-delhi-ncr"
              element={<CarpoolingPage />}
            />

            <Route
              exact
              path="/admin"
              element={
                isLoggedIn ? <Navigate to="/admin-panel" /> : <AdminLogin />
              }
            ></Route>
            <Route
              exact
              path="/admin-panel"
              element={isLoggedIn ? <AdminPanel /> : <Navigate to="/" />}
            ></Route>

            <Route exact path="/blog-create" element={<CreateBlogBlog />} />
            <Route
              exact
              path="/blog-section-create"
              element={<CreateBlogBlogSection />}
            />
            <Route
              exact
              path="/blog-edit/:blog_id"
              element={<BlogBlogEdit />}
            />
            <Route exact path="/blogss" element={<AllBlogsCard />} />
            <Route exact path="/blogss/:blog_id" element={<BlogCardDetail />} />
            <Route
              exact
              path="/blog-section/:blog_id"
              element={<BlogSectionShow />}
            />
            <Route
              exact
              path="/blog-section-edit/:blog_id/:subtitle_id"
              element={<BlogSectionEdit />}
            />
            <Route exact path="/seo-list" element={<SeoList />} />
            <Route exact path="/seo-create" element={<PurposeSeo />} />
            <Route exact path="/seo-edit/:slug_id" element={<EditSeo />} />
            <Route exact path="/offers" element={<OfferPage />} />
            <Route
              exact
              path="/7-rides-7-days-offer-for-ride-giver"
              element={<OfferPageDrive />}
            />
            <Route exact path="/ride-free-offer" element={<OfferPageRider />} />
          </Routes>
        </Router>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeButton={true}
        style={customToastStyle}
      />
    </>
  );
};
export default App;
